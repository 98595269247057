<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Restitutie Beleid</li>
            </ul>
            <h2>Restitutie Beleid</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="terms-of-service-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="terms-of-service-content">
                    <p>Ons restitutie beleid is heel eenvoudig.</p>

                    <p>Wij verkopen via www.theoriecoach.nl alleen digitale producten (theoriecursus voor de auto,) en
                        streamingsdiensten. Omruilen is dan ook niet mogelijk.</p>

                    <p>Heb je spijt van je aankoop en wil je het na betaling toch annuleren?</p>

                    <p>Dit kan alleen wanneer er nog geen streamingsdienst is afgespeeld en wanneer er nog geen account
                        is geactiveerd of document is gedownload. Hierbij wordt geen onderscheid gemaakt tussen volledig
                        afspelen of gedeeltelijk afspelen.</p>

                    <p>Ons systeem kan makkelijk in kaart brengen of een streamingsdienst is geactiveerd.</p>

                    <p>Restitutie geschiedt alleen giraal.</p>

                    <p>Voor vragen kun je altijd contact opnemen met <a href="mailto:info@theoriecoach.nl">info@theoriecoach.nl</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
