<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/theoriepakketten" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Theorie pakketten</a></li>
                    <li class="nav-item"><a routerLink="/gratisvideos" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Gratis Video's</a></li>
                    <li class="nav-item"><a routerLink="/contact" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Contact</a></li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/theoriepakketten" class="nav-link">Theorie pakketten</a></li>
                    <li class="nav-item"><a routerLink="/gratisvideos" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Gratis Video's</a></li>
                    <li class="nav-item"><a routerLink="/contact" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Contact</a></li>
                </ul>
            </div>
            <div class="others-option d-flex align-items-center">
                <div class="option-item">
                    <a href="https://app.theoriecoach.nl" class="default-btn"><i class="flaticon-user"></i>Inloggen<span></span></a>
                </div>
            </div>
        </nav>
    </div>
</div>
