<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Theoriepakketten</li>
            </ul>
            <h2>Theoriepakketten Theoriecoach</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box bg-color">
                    <div class="courses-image">
                        <a (click)="goToCheckout(3, 44.49, '1 week')" class="d-block image">
                            <img src="assets/img/1week.png" alt="image">
                        </a>
                        <a (click)="goToCheckout(3, 44.49, '1 week')" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">€44,49</div>
<!--                        <div class="old price shadow">€44,99</div>-->
                    </div>
                    <div class="courses-content">
                        <h3><a (click)="goToCheckout(3, 44.49, '1 week')">1 week</a></h3>
                        <ul class="courses-box-footer align-items-center">
                            <li><i class='flaticon-checkmark'></i> Online videocursus</li>
                            <li><i class='flaticon-checkmark'></i> Overzicht verkeersborden</li>
                            <li><i class='flaticon-checkmark'></i> Oefenvragen per hoofdstuk</li>
                            <li><i class='flaticon-checkmark'></i> Oefen examens</li>
                            <li><i class='flaticon-checkmark'></i> Week lang onbeperkt oefenen</li>
                            <li><i class='flaticon-checkmark'></i> Gebaseerd op het CBR theorie examen</li>
                            <li><i class='flaticon-checkmark'></i> Meest voordelige pakket</li>
                            <li><i class='flaticon-checkmark'></i> Onbeperkt toegang</li>
                            <li>&nbsp;</li>
                        </ul>
                    </div>
                    <hr>
                    <div class="text-center">
                        <button (click)="goToCheckout(3, 44.49, '1 week')" type="button" class="default-btn"><i class="flaticon-tag"></i>Direct kopen<span></span></button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box bg-color">
                    <div class="courses-image">
                        <a (click)="goToCheckout(5, 55.99, '2 weken')" class="d-block image">
                            <img src="assets/img/3weken.png" alt="image">
                        </a>
                        <a (click)="goToCheckout(5, 55.99, '2 weken')" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">€55,99</div>
<!--                        <div class="old price shadow">€55,99</div>-->
                    </div>
                    <div class="courses-content">
                        <h3><a (click)="goToCheckout(5, 55.99, '2 weken')">3 weken</a></h3>
                        <ul class="courses-box-footer align-items-center">
                            <li><i class='flaticon-checkmark'></i> Online videocursus</li>
                            <li><i class='flaticon-checkmark'></i> Overzicht verkeersborden</li>
                            <li><i class='flaticon-checkmark'></i> Oefenvragen per hoofdstuk</li>
                            <li><i class='flaticon-checkmark'></i> Oefen examens</li>
                            <li><i class='flaticon-checkmark'></i> 3 weken lang onbeperkt oefenen</li>
                            <li><i class='flaticon-checkmark'></i> Gebaseerd op het CBR theorie examen</li>
                            <li><i class='flaticon-checkmark'></i> Meest voordelige pakket</li>
                            <li><i class='flaticon-checkmark'></i> Onbeperkt toegang</li>
                            <li>&nbsp;</li>
                        </ul>
                    </div>
                    <hr>
                    <div class="text-center">
                        <button (click)="goToCheckout(5, 55.99, '2 weken')" type="button" class="default-btn"><i class="flaticon-tag"></i>Direct kopen<span></span></button>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box bg-color">
                    <div class="courses-image">
                        <a (click)="goToCheckout(4, 32.99, '4 weken')" class="d-block image">
                            <img src="assets/img/4weken.png" alt="image">
                        </a>
                        <a (click)="goToCheckout(4, 32.99, '4 weken')" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">€32,99</div>
                        <div class="old price shadow">€65,99</div>
                    </div>
                    <div class="courses-content">
                        <h3><a (click)="goToCheckout(4, 32.99, '4 weken')">4 weken</a></h3>
                        <ul class="courses-box-footer align-items-center">
                            <li><i class='flaticon-checkmark'></i> Online videocursus</li>
                            <li><i class='flaticon-checkmark'></i> Overzicht verkeersborden</li>
                            <li><i class='flaticon-checkmark'></i> Oefenvragen per hoofdstuk</li>
                            <li><i class='flaticon-checkmark'></i> Oefen examens</li>
                            <li><i class='flaticon-checkmark'></i> 4 weken lang onbeperkt oefenen</li>
                            <li><i class='flaticon-checkmark'></i> Gebaseerd op het CBR theorie examen</li>
                            <li><i class='flaticon-checkmark'></i> Meest voordelige pakket</li>
                            <li><i class='flaticon-checkmark'></i> Onbeperkt toegang</li>
                            <li><i class='flaticon-checkmark'></i> Meest gekozen pakket</li>

                        </ul>
                    </div>
                    <hr>
                    <div class="text-center">
                        <button (click)="goToCheckout(4, 32.99, '4 weken')" type="button" class="default-btn"><i class="flaticon-tag"></i>Direct kopen<span></span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
