<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <h2>Vragen? Stel ze gerust!</h2>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobiel: <a href="tel:+44457895789">(+31)0613246578</a></p>
                            <p>Mail: <a href="mailto:info@theoriecoach.nl">info@theoriecoach.nl</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Openingstijden</h3>
                            <p>Maandag - Vrijdag: 10:00 - 16:00</p>
                            <p>Zaterdag & Zondag: gesloten</p>
                        </li>
                        <li>
                            <img src="/assets/img/socailmediacontact.png" alt="Social Media Theoriecoach">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Stel je vraag</h2>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Naam">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Naam is verplicht.</div>
                                        <div *ngIf="name.errors.minlength">Naam moet minimaal uit {{ name.errors.minlength.requiredLength }} bestaan.</div>
                                        <div *ngIf="name.errors.maxlength">Naam mag maximaal uit 50 caracters bestaan.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email is verplicht.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="subject" type="text" #number="ngModel" class="form-control" id="subject" placeholder="Onderwerp">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Onderwerp is verplicht.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Je vraag..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Bericht is verplicht.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Verstuur<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
