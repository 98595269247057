<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Gratis Video's van Theoriecoach</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <div style="position:relative; width:1px; min-width:100%;padding-bottom:56.25%;"><iframe allow="autoplay" class='spotlightr' allowtransparency="true" style="width:1px; min-width:100%; height: 100%; position:absolute" allowfullscreen="true" src="https://theoriecoach.cdn.spotlightr.com/watch/MTMzMTA0Ng==?fallback=true" frameborder="0" scrolling="no" name="videoPlayer"> </iframe></div>
                    </div>
                    <div class="post-content">
                        <h3>Welk bord geeft een einde spitstrook aan?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <div style="position:relative; width:1px; min-width:100%;padding-bottom:56.25%;"><iframe allow="autoplay" class='spotlightr' allowtransparency="true" style="width:1px; min-width:100%; height: 100%; position:absolute" allowfullscreen="true" src="https://theoriecoach.cdn.spotlightr.com/watch/MTMzMTA0Mw==?fallback=true" frameborder="0" scrolling="no" name="videoPlayer"> </iframe></div>
                    </div>
                    <div class="post-content">
                        <h3>Wat is de juiste volgorde van voor laten gaan?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <div style="position:relative; width:1px; min-width:100%;padding-bottom:56.25%;"><iframe allow="autoplay" class='spotlightr' allowtransparency="true" style="width:1px; min-width:100%; height: 100%; position:absolute" allowfullscreen="true" src="https://theoriecoach.cdn.spotlightr.com/watch/MTMzMTAzNg==?fallback=true" frameborder="0" scrolling="no" name="videoPlayer"> </iframe></div>
                    </div>
                    <div class="post-content">
                        <h3>Mag je hier rechts inhalen?</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <div style="position:relative; width:1px; min-width:100%;padding-bottom:56.25%;"><iframe allow="autoplay" class='spotlightr' allowtransparency="true" style="width:1px; min-width:100%; height: 100%; position:absolute" allowfullscreen="true" src="https://theoriecoach.cdn.spotlightr.com/watch/MTMzMTAzNA==?fallback=true" frameborder="0" scrolling="no" name="videoPlayer"> </iframe></div>
                    </div>
                    <div class="post-content">
                        <h3>Mag je hier inhalen?</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
