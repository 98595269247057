<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Algemenen voorwaarden Theoriecoach</li>
            </ul>
            <h2>Algemenen voorwaarden Theoriecoach</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="privacy-policy-content">
                    <p>Deze website wordt beheerd door Theorie Coach. Op de hele site verwijzen de termen 'wij', 'ons'
                        en 'onze' naar Theorie Coach. Theorie Coach biedt deze website, inclusief alle informatie,
                        hulpmiddelen en diensten die beschikbaar zijn vanaf deze site, aan u, de gebruiker, onder
                        voorbehoud van uw aanvaarding van alle voorwaarden, beleidsregels en mededelingen die hier
                        worden vermeld.</p>


                    <p>Door onze site te bezoeken en / of iets van ons te kopen, neemt u deel aan onze "Service" en gaat
                        u ermee akkoord gebonden te zijn aan de volgende algemene voorwaarden ("Servicevoorwaarden",
                        "Voorwaarden"), inclusief die aanvullende voorwaarden en bepalingen waarnaar hierin wordt
                        verwezen en / of beschikbaar is via een hyperlink. Deze Servicevoorwaarden zijn van toepassing
                        op alle gebruikers van de site, inclusief maar niet beperkt tot gebruikers die browsers,
                        leveranciers, klanten, verkopers en / of leveranciers van inhoud zijn.</p>


                    <p>Lees deze Servicevoorwaarden zorgvuldig voordat u onze website bezoekt of gebruikt. Door toegang
                        te krijgen tot of gebruik te maken van een deel van de site, gaat u ermee akkoord gebonden te
                        zijn aan deze Servicevoorwaarden. Als u niet akkoord gaat met alle bepalingen en voorwaarden van
                        deze overeenkomst, mag u geen toegang krijgen tot de website of diensten gebruiken. Als deze
                        Servicevoorwaarden als een aanbieding worden beschouwd, is de acceptatie uitdrukkelijk beperkt
                        tot deze Servicevoorwaarden.</p>


                    <p>Alle nieuwe functies of hulpmiddelen die aan de huidige winkel worden toegevoegd, vallen ook
                        onder de Servicevoorwaarden. U kunt op elk moment op deze pagina de meest recente versie van de
                        Servicevoorwaarden bekijken. We behouden ons het recht voor om elk deel van deze
                        Servicevoorwaarden bij te werken, te wijzigen of te vervangen door updates en / of wijzigingen
                        op onze website te plaatsen. Het is uw verantwoordelijkheid om deze pagina regelmatig te
                        controleren op wijzigingen. Uw voortdurende gebruik van of toegang tot de website na het
                        plaatsen van wijzigingen houdt acceptatie van die wijzigingen in.</p>


                    <p>Onze Transacties worden gedaan door Mollie. Ze bieden ons het platform waarmee we onze producten
                        en diensten aan u kunnen verkopen.</p>


                    <h3>SECTIE 1 - VOORWAARDEN VOOR ONLINE WINKELS</h3>


                    <p>Door akkoord te gaan met deze Servicevoorwaarden, verklaart u dat u ten minste de
                        meerderjaringsaandeelhouder bent in uw staat of provincie, of dat u meerderjarig bent in uw
                        staat of provincie en dat u ons toestemming hebt gegeven om sta een van uw minderjarige
                        afhankelijken toe om deze site te gebruiken.</p>

                    <p>U mag onze producten niet gebruiken voor illegale of ongeoorloofde doeleinden noch mag u, in het
                        gebruik van de Service, wetten overtreden in uw rechtsgebied (inclusief maar niet beperkt tot
                        auteursrechtwetten).</p>

                    <p>Je mag geen wormen of virussen of een code van destructieve aard verzenden.</p>

                    <p>Een schending of overtreding van een van de Voorwaarden zal resulteren in een onmiddellijke
                        beëindiging van uw Services.</p>


                    <h3>SECTIE 2 - ALGEMENE VOORWAARDEN</h3>


                    <p>We behouden ons het recht voor om service aan iedereen om welke reden dan ook op elk moment te
                        weigeren.</p>

                    <p>U begrijpt dat uw inhoud (niet inclusief creditcardinformatie) ongecodeerd kan worden
                        overgedragen en (a) transmissies over verschillende netwerken omvat; en (b) wijzigingen om te
                        voldoen aan en aan te passen aan technische vereisten van aangesloten netwerken of apparaten.
                        Creditcardinformatie wordt altijd gecodeerd tijdens overdracht via netwerken.</p>

                    <p>U stemt ermee in om geen enkel deel van de Service, het gebruik van de Service of toegang tot de
                        Service of enig contact op de website waarop de service wordt aangeboden te reproduceren,
                        dupliceren, kopiëren, verkopen, door te verkopen of te exploiteren, zonder uitdrukkelijke
                        schriftelijke toestemming van ons.</p>

                    <p>De titels die in deze overeenkomst worden gebruikt, zijn alleen voor het gemak opgenomen en
                        zullen deze Voorwaarden niet beperken of anderszins beïnvloeden.</p>


                    <h3>SECTIE 3 - NAUWKEURIGHEID, VOLLEDIGHEID EN TIJDIGHEID VAN INFORMATIE</h3>


                    <p>Wij zijn niet verantwoordelijk als informatie die op deze site beschikbaar is, niet juist,
                        volledig of actueel is. Het materiaal op deze site is alleen bedoeld als algemene informatie en
                        mag niet worden gebruikt als basis voor het nemen van beslissingen zonder voorafgaand,
                        accurater, vollediger of actuelere informatiebronnen te raadplegen. Elke afhankelijkheid van het
                        materiaal op deze site is op eigen risico.</p>

                    <p>Deze site kan bepaalde historische informatie bevatten. Historische informatie is
                        noodzakelijkerwijs niet actueel en wordt alleen ter referentie aangeboden. We behouden ons het
                        recht voor om de inhoud van deze site op elk moment te wijzigen, maar we zijn niet verplicht om
                        informatie op onze site bij te werken. U gaat ermee akkoord dat het uw verantwoordelijkheid is
                        om wijzigingen in onze site te controleren.</p>


                    <h3>SECTIE 4 - WIJZIGINGEN IN DE SERVICE EN PRIJZEN</h3>


                    <p>De prijzen voor onze producten kunnen zonder voorafgaande kennisgeving worden gewijzigd.</p>

                    <p>We behouden ons het recht voor om op elk gewenst moment de Service (of enig onderdeel of inhoud
                        daarvan) zonder kennisgeving te wijzigen of te beëindigen.</p>

                    <p>Wij zijn niet aansprakelijk jegens u of een derde partij voor enige wijziging, prijswijziging,
                        opschorting of beëindiging van de Service. Kortingscodes zijn niet geldig in combinatie met
                        andere kortingscodes en/of kortingsacties.</p>


                    <h3>SECTIE 5 - PRODUCTEN OF DIENSTEN (indien van toepassing)</h3>


                    <p>Bepaalde producten of diensten zijn mogelijk uitsluitend online beschikbaar via de website. Deze
                        producten of diensten kunnen beperkte hoeveelheden bevatten en kunnen alleen worden
                        geretourneerd of geruild volgens ons retourbeleid.</p>

                    <p>We hebben er alles aan gedaan om de kleuren en afbeeldingen van onze producten die in de winkel
                        verschijnen zo nauwkeurig mogelijk weer te geven. We kunnen niet garanderen dat de weergave van
                        elke kleur op uw computerscherm correct zal zijn.</p>

                    <p>We behouden ons het recht voor, maar zijn niet verplicht, om de verkoop van onze producten of
                        diensten aan een persoon, geografische regio of jurisdictie te beperken. We kunnen dit recht van
                        geval tot geval uitoefenen. We behouden ons het recht voor om de hoeveelheden producten of
                        services die we aanbieden te beperken. Alle beschrijvingen van producten of prijzen van
                        producten kunnen op elk moment en zonder voorafgaande kennisgeving worden gewijzigd, naar eigen
                        goeddunken van ons. We behouden ons het recht voor om elk product op elk gewenst moment te
                        beëindigen. Elke aanbieding voor een product of dienst gedaan op deze site is ongeldig waar
                        verboden.</p>

                    <p>We garanderen niet dat de kwaliteit van producten, services, informatie of ander materiaal dat u
                        hebt gekocht of verkregen, aan uw verwachtingen zal voldoen, of dat fouten in de Service zullen
                        worden gecorrigeerd.</p>


                    <h3>SECTIE 6 - NAUWKEURIGHEID VAN FACTURERING EN ACCOUNTGEGEVENS</h3>


                    <p>We behouden ons het recht voor om bestellingen die u bij ons plaatst te weigeren. We kunnen, naar
                        eigen goeddunken, de gekochte hoeveelheden per persoon, per huishouden of per bestelling
                        beperken of annuleren. Deze beperkingen kunnen bestaan uit bestellingen geplaatst door of onder
                        dezelfde klantaccount, dezelfde creditcard en / of bestellingen die hetzelfde factuur- en / of
                        verzendadres gebruiken. In het geval dat we een wijziging aanbrengen of een bestelling
                        annuleren, kunnen we proberen u hiervan op de hoogte te stellen door contact op te nemen met de
                        e-mail en / of het factuuradres / telefoonnummer dat werd opgegeven op het moment dat de
                        bestelling werd geplaatst. We behouden ons het recht voor om bestellingen te beperken of te
                        verbieden die, naar onze mening, lijken te zijn geplaatst door dealers, wederverkopers of
                        distributeurs.</p>


                    <p>U stemt ermee in om actuele, volledige en correcte aankoop- en accountinformatie te verstrekken
                        voor alle aankopen die in onze winkel zijn gedaan. U gaat ermee akkoord om uw account en andere
                        informatie, inclusief uw e-mailadres en creditcardnummers en vervaldatums, zo snel mogelijk bij
                        te werken, zodat wij uw transacties kunnen voltooien en zo nodig contact met u kunnen opnemen.</p>


                    <p>Raadpleeg ons retourbeleid voor meer informatie.</p>


                    <h3>SECTIE 7 - OPTIONELE HULPMIDDELEN</h3>


                    <p>We kunnen u toegang verschaffen tot tools van derden waarop wij geen toezicht houden, noch
                        controle of invoer hebben. U erkent en gaat ermee akkoord dat wij toegang bieden tot dergelijke
                        hulpmiddelen "zoals ze zijn" en "zoals beschikbaar" zonder garanties, verklaringen of
                        voorwaarden van welke aard dan ook en zonder enige goedkeuring. We zijn op geen enkele manier
                        aansprakelijk uit hoofde van of in verband met uw gebruik van optionele tools van derden.</p>

                    <p>Elk gebruik door u van optionele hulpmiddelen aangeboden via de site is geheel voor eigen risico
                        en discretie en u dient ervoor te zorgen dat u bekend bent met en akkoord gaat met de
                        voorwaarden waarop tools worden aangeboden door de relevante externe aanbieder (s).</p>

                    <p>We kunnen in de toekomst ook nieuwe diensten en / of functies aanbieden via de website (inclusief
                        de release van nieuwe hulpmiddelen en bronnen). Dergelijke nieuwe functies en / of services
                        vallen ook onder deze Servicevoorwaarden.</p>


                    <h3>SECTIE 8 - KOPPELINGEN DERDE PARTIJEN</h3>


                    <p>Bepaalde inhoud, producten en services die beschikbaar zijn via onze service kunnen materialen
                        van derden bevatten.</p>

                    <p>Links van derden op deze site kunnen u verwijzen naar websites van derden die niet aan ons zijn
                        gelieerd. Wij zijn niet verantwoordelijk voor het onderzoeken of evalueren van de inhoud of
                        nauwkeurigheid en we geven geen garantie en zijn niet aansprakelijk of verantwoordelijk voor
                        materialen of websites van derden, of voor andere materialen, producten of diensten van derden.</p>

                    <p>Wij zijn niet aansprakelijk voor schade of schade met betrekking tot de aankoop of het gebruik
                        van goederen, diensten, middelen, inhoud of andere transacties die worden uitgevoerd in verband
                        met websites van derden. Lees het beleid en de praktijken van derden zorgvuldig door en zorg
                        ervoor dat u ze begrijpt voordat u een transactie onderneemt. Klachten, claims, zorgen of vragen
                        met betrekking tot producten van derden moeten worden gericht aan de derde partij.</p>


                    <h3>SECTIE 9 - COMMENTAREN VAN DE GEBRUIKER, FEEDBACK EN ANDERE MIDDELEN</h3>


                    <p>Als u op ons verzoek bepaalde specifieke opmerkingen verzendt (bijvoorbeeld wedstrijdinzendingen)
                        of zonder een verzoek van ons stuurt u creatieve ideeën, suggesties, voorstellen, plannen of
                        andere materialen, online, per e-mail, per post of anderszins (gezamenlijk, 'opmerkingen'),
                        stemt u ermee in dat wij op elk moment, zonder enige beperking, eventuele opmerkingen die u ons
                        doorstuurt, mogen bewerken, kopiëren, publiceren, distribueren, vertalen en anderszins
                        gebruiken. Wij zijn en zijn niet verplicht (1) om eventuele opmerkingen vertrouwelijk te houden;
                        (2) om compensatie te betalen voor eventuele opmerkingen; of (3) om op opmerkingen te reageren.</p>

                    <p>We kunnen, maar zijn niet verplicht tot het controleren, bewerken of verwijderen van inhoud
                        waarvan wij naar eigen goeddunken bepalen dat deze onwettig, aanstootgevend, bedreigend,
                        lasterlijk, lasterlijk, pornografisch, obsceen of anderszins aanstootgevend is of in strijd is
                        met het intellectuele eigendom van een partij of deze Servicevoorwaarden.</p>

                    <p>U stemt ermee in dat uw opmerkingen geen rechten van derden schenden, inclusief auteursrecht,
                        handelsmerk, privacy, persoonlijkheid of ander persoonlijk of eigendomsrecht. U gaat er verder
                        mee akkoord dat uw opmerkingen geen smadelijk of anderszins onwettig, beledigend of obsceen
                        materiaal bevatten, of een computervirus of andere malware bevatten die op enige manier de
                        werking van de Service of een gerelateerde website zou kunnen beïnvloeden. U mag geen vals
                        e-mailadres gebruiken, zich voordoen als iemand anders dan uzelf, of op een andere manier ons of
                        derde partijen misleiden met betrekking tot de oorsprong van eventuele opmerkingen. U bent zelf
                        verantwoordelijk voor eventuele opmerkingen en nauwkeurigheid. Wij nemen geen
                        verantwoordelijkheid en aanvaarden geen aansprakelijkheid voor eventuele opmerkingen die door u
                        of een derde partij worden geplaatst.</p>


                    <h3>SECTIE 10 - PERSOONLIJKE INFORMATIE</h3>


                    <p>Uw inzending van persoonlijke informatie via de winkel valt onder ons Privacybeleid. Om ons
                        privacybeleid te bekijken.</p>


                    <h3>SECTIE 11 - FOUTEN, ONNAUWKEURIGHEDEN EN WEGLATINGEN</h3>


                    <p>Af en toe kan er informatie op onze site of in de Dienst zijn die typografische fouten,
                        onnauwkeurigheden of weglatingen bevat die verband kunnen houden met productbeschrijvingen,
                        prijzen, promoties, aanbiedingen, verzendkosten van producten, transittijden en beschikbaarheid.
                        Wij behouden ons het recht voor om eventuele fouten, onnauwkeurigheden of weglatingen te
                        corrigeren en om informatie te wijzigen of bij te werken of om bestellingen te annuleren als
                        enige informatie in de Service of op een gerelateerde website op enig moment onnauwkeurig is
                        (inclusief nadat u uw bestelling hebt ingediend).</p>

                    <p>Wij zijn niet verplicht om informatie in de Service of op een gerelateerde website bij te werken,
                        te wijzigen of te verduidelijken, inclusief maar niet beperkt tot prijsinformatie, behalve zoals
                        vereist door de wet. Er mag geen specifieke update- of verversingsdatum worden toegepast in de
                        Service of op een gerelateerde website om aan te geven dat alle informatie in de Service of op
                        een gerelateerde website is gewijzigd of bijgewerkt.</p>


                    <h3>SECTIE 12 - VERBODEN GEBRUIK</h3>


                    <p>In aanvulling op andere verbodsbepalingen zoals uiteengezet in de Servicevoorwaarden, is het u
                        verboden om de site of de inhoud ervan te gebruiken: (a) voor enig onwettig doel; (b) om anderen
                        te vragen onwettige handelingen uit te voeren of eraan deel te nemen; (c) om internationale,
                        federale, provinciale of provinciale voorschriften, regels, wetten of lokale verordeningen te
                        overtreden; (d) om inbreuk te plegen op of onze intellectuele eigendomsrechten of de
                        intellectuele eigendomsrechten van anderen te schenden; (e) lastigvallen, misbruiken, beledigen,
                        schaden, belasteren, belasteren, kleineren, intimideren of discrimineren op basis van geslacht,
                        seksuele geaardheid, religie, etniciteit, ras, leeftijd, nationale afkomst of handicap; (f) om
                        valse of misleidende informatie in te dienen; (g) voor het uploaden of verzenden van virussen of
                        andere soorten kwaadaardige code die op enigerlei wijze zullen of kunnen worden gebruikt die de
                        functionaliteit of werking van de Service of van een gerelateerde website, andere websites of
                        internet beïnvloeden; (h) om de persoonlijke informatie van anderen te verzamelen of te volgen;
                        (i) spam, phish, pharm, pretext, spider, crawl of scrape; (j) voor elk obsceen of immoreel doel;
                        of (k) om te interfereren met of omzeilen van de beveiligingskenmerken van de Service of een
                        gerelateerde website, andere websites of internet. We behouden ons het recht voor om uw gebruik
                        van de Service of een gerelateerde website te beëindigen wegens schending van het verboden
                        gebruik.</p>


                    <h3>SECTIE 13 - AFWIJZING VAN GARANTIES; BEPERKING VAN AANSPRAKELIJKHEID</h3>


                    <p>We kunnen niet garanderen, garanderen of garanderen dat uw gebruik van onze service
                        ononderbroken, tijdig, veilig of vrij van fouten zal zijn.</p>

                    <p>We garanderen niet dat de resultaten die kunnen worden verkregen door het gebruik van de service
                        accuraat of betrouwbaar zijn.</p>

                    <p>U gaat ermee akkoord dat we van tijd tot tijd de service voor onbepaalde tijd kunnen verwijderen
                        of de service op elk moment kunnen annuleren, zonder kennisgeving aan u.</p>

                    <p>U stemt er uitdrukkelijk mee in dat uw gebruik van of de onmogelijkheid om de service te
                        gebruiken op uw eigen risico is. De service en alle producten en diensten die u via de service
                        worden geleverd, zijn (behalve zoals uitdrukkelijk door ons wordt aangegeven) "zoals ze zijn" en
                        "zoals beschikbaar" voor uw gebruik, zonder enige vertegenwoordiging, garanties of voorwaarden
                        van welke aard dan ook, hetzij uitdrukkelijk of impliciet, inclusief alle impliciete garanties
                        of voorwaarden van verkoopbaarheid, handelskwaliteit, geschiktheid voor een bepaald doel,
                        duurzaamheid, titel en niet-inbreuk.</p>

                    <p>In geen geval zullen Theorie Coach, onze directeuren, functionarissen, werknemers, gelieerde
                        ondernemingen, agenten, aannemers, stagiaires, leveranciers, dienstverleners of licentiegevers
                        aansprakelijk zijn voor enig letsel, verlies, claim of enige directe, indirecte, incidentele,
                        punitieve, speciale, of gevolgschade van welke aard dan ook, met inbegrip van maar niet beperkt
                        tot gederfde winst, gederfde inkomsten, gemiste besparingen, verlies van gegevens,
                        vervangingskosten of gelijkaardige schade, ongeacht of deze gebaseerd is op een contract,
                        onrechtmatige daad (inclusief nalatigheid), strikte aansprakelijkheid of anderszins
                        voortvloeiend uit uw gebruik van een dienst of producten die zijn aangeschaft via de service, of
                        voor enige andere claim die op enigerlei wijze verband houdt met uw gebruik van de service of
                        een product, inclusief, maar niet beperkt tot, fouten of weglatingen in welke inhoud dan ook, of
                        enig verlies of schade van welke aard dan ook die is opgelopen als gevolg van het gebruik van de
                        service of enige inhoud (of product) die is gepost, verzonden of anderszins beschikbaar is
                        gesteld via de service, zelfs als deze op de hoogte is gesteld van hun mogelijkheid. Omdat in
                        sommige staten of rechtsgebieden de uitsluiting of beperking van aansprakelijkheid voor gevolg-
                        of incidentele schade niet is toegestaan, zal onze aansprakelijkheid in dergelijke staten of
                        rechtsgebieden beperkt zijn tot het maximale aantal dat wettelijk is toegestaan.</p>


                    <h3>SECTIE 14 - VRIJWARING</h3>


                    <p>U stemt ermee in om Theorie Coach en onze moedermaatschappijen, dochterondernemingen, gelieerde
                        ondernemingen, partners, functionarissen, directeuren, agenten, aannemers, licentiegevers,
                        dienstverleners, onderaannemers, leveranciers, stagiairs en werknemers schadeloos te stellen, te
                        verdedigen en te vrijwaren van elke claim of eis, inclusief redelijke advocatenhonoraria,
                        gemaakt door een derde partij als gevolg van of voortvloeiend uit uw schending van deze
                        Servicevoorwaarden of de documenten die zij opnemen door middel van referentie, of uw
                        overtreding van een wet of de rechten van een derde partij.</p>


                    <h3>SECTIE 15 - AFWIKKELBAARHEID</h3>


                    <p>In het geval dat een bepaling van deze Servicevoorwaarden als onwettig, ongeldig of
                        niet-afdwingbaar wordt beschouwd, zal dergelijke bepaling niettemin uitvoerbaar zijn voor zover
                        als toegestaan door de toepasselijke wetgeving, en het niet-afdwingbare deel wordt geacht te
                        zijn gescheiden van deze Voorwaarden Service, een dergelijke vaststelling heeft geen invloed op
                        de geldigheid en afdwingbaarheid van andere resterende bepalingen.</p>


                    <h3>SECTIE 16 - BEËINDIGING</h3>


                    <p>De verplichtingen en aansprakelijkheden van de partijen die voorafgaand aan de beëindigingsdatum
                        zijn aangegaan, blijven na beëindiging van deze overeenkomst voor alle doeleinden.</p>

                    <p>Deze Servicevoorwaarden zijn van kracht tenzij en totdat ze door u of ons worden beëindigd. U
                        kunt deze Servicevoorwaarden te allen tijde beëindigen door ons op de hoogte te stellen dat u
                        onze Diensten niet langer wenst te gebruiken of wanneer u onze website niet langer gebruikt.</p>

                    <p>Als u, naar eigen goeddunken, faalt, of we vermoeden dat u hebt gefaald, om aan enige voorwaarde
                        of bepaling van deze Servicevoorwaarden te voldoen, kunnen we deze overeenkomst op elk moment en
                        zonder kennisgeving beëindigen en blijft u aansprakelijk voor alle bedragen die verschuldigd
                        zijn. tot en met de datum van beëindiging; en / of dienovereenkomstig kan het u toegang tot onze
                        Diensten (of een deel daarvan) ontzeggen.</p>


                    <h3>SECTIE 17 - VOLLEDIGE OVEREENKOMST</h3>


                    <p>Het feit dat wij een recht of bepaling van deze Servicevoorwaarden niet uitoefenen of afdwingen,
                        betekent niet dat we afstand doen van dat recht of deze bepaling.</p>

                    <p>Deze Servicevoorwaarden en alle beleidsregels of bedieningsregels die door ons op deze site of
                        met betrekking tot de Service worden geplaatst, vormen de volledige overeenkomst en afspraak
                        tussen u en ons en bepalen uw gebruik van de Service en vervangen alle eerdere of gelijktijdige
                        overeenkomsten, mededelingen en voorstellen , hetzij mondeling of schriftelijk, tussen u en ons
                        (inclusief, maar niet beperkt tot, eerdere versies van de Servicevoorwaarden).</p>

                    <p>Eventuele onduidelijkheden in de interpretatie van deze Servicevoorwaarden mogen niet worden
                        uitgelegd tegen de redacteur.</p>


                    <h3>SECTIE 18 - TOEPASSELIJKE WET</h3>


                    <p>Deze Servicevoorwaarden en eventuele afzonderlijke overeenkomsten waarbij wij u Services
                        verlenen, worden beheerst door en geïnterpreteerd in overeenstemming met de wetten van het
                        Nederlandse recht.</p>


                    <h3>SECTIE 19 - WIJZIGINGEN IN DE SERVICEVOORWAARDEN</h3>


                    <p>U kunt op elk moment op deze pagina de meest recente versie van de Servicevoorwaarden bekijken.</p>

                    <p>We behouden ons het recht voor om naar eigen goeddunken elk onderdeel van deze Servicevoorwaarden
                        bij te werken, te wijzigen of te vervangen door updates en wijzigingen op onze website te
                        plaatsen. Het is uw verantwoordelijkheid om regelmatig onze website te controleren op
                        wijzigingen. Uw voortdurende gebruik van of toegang tot onze website of de Service na het
                        plaatsen van enige wijzigingen in deze Servicevoorwaarden houdt acceptatie van die wijzigingen
                        in.</p>


                    <h3>SECTIE 20 - CONTACTGEGEVENS</h3>


                    <p>Vragen over de Servicevoorwaarden moeten aan ons worden gestuurd: <a href="mailto:info@theoriecoach.nl">info@theoriecoach.nl</a></p>



                </div>
            </div>
        </div>
    </div>
</div>
